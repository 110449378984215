<template>
	<div class="document">
		<div class="cols2">
			<section data-name:title>
				<header>{{ i18n('returns.title') }}</header>

				<div class="grid-align">
					<input-combo v-model="value['shippingNumber']" v-bind="auto('shippingNumber')" />
					<input-combo v-model="value['deposit']" v-bind="auto('deposit')" />
					<input-combo v-model="value['collectingWeek']" v-bind="auto('collectingWeek')" />
					<input-combo v-model="value['carouselCount']" v-bind="auto('carouselCount')" />
					<input-combo v-model="value['packageCount']" v-bind="auto('packageCount')" />
					<input-combo v-model="value['state']" v-bind="auto('state')" />
					<input-combo v-model="value['comment']" v-bind="auto('comment')" />

					<input-combo v-model="value['notes']" v-bind="auto('notes')" />
					<input-combo v-model="value['archived']" v-bind="auto('archived')" />
				</div>
			</section>
			<section v-if="!edit" data-name:metas>
				<header>{{ i18n('returns.metas') }}</header>
				<div class="grid-align">
					<input-combo :model-value="document['creationDate']" :edit="false" v-bind="auto('creationDate')" />
					<input-combo :model-value="document['creationUser']" :edit="false" v-bind="auto('creationUser')" />
					<input-combo :model-value="document['modificationDate']" :edit="false" v-bind="auto('modificationDate')" />
					<input-combo :model-value="document['modificationUser']" :edit="false" v-bind="auto('modificationUser')" />
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import mixin from './mixin.js' ;

export default {
	mixins: [mixin]
} ;
</script>

<style scoped>
.document {
	grid-template: "title metas" / 1fr 1fr;
}
@media (max-width: 1024px) {
	.document {
		grid-template:
			"title"
			"metas" / 1fr;
	}
}
</style>
