<template>
	<div v-if="componantName" class="filter" :class="[inheritedClass, componantName]">
		<label v-if="label" :for="uuid" class="name">
			{{ labelValue }}
		</label>
		<component
			:is="componantName"
			:id="uuid"
			v-bind="$attrs"
		/>
	</div>
</template>

<script>
import { useId } from 'vue' ;
import importGlobToObject from '@/libRestQuery/js/utils/importGlobToObject.js' ;
const filters = import.meta.glob( './filter/*.vue' , { eager: true } ) ;

export default {
	components: {
		...importGlobToObject( filters )
	} ,
	inheritAttrs: false ,
	props: {
		class: {
			type: String ,
			default: null
		} ,
		type: {
			type: String ,
			default: null
		} ,
		label: {
			type: [Boolean , String] ,
			default: true
		}
	} ,
	data: function() {
		return {
			uuid: useId()
		} ;
	} ,
	computed: {
		inheritedClass: function() {
			return this.class ;
		} ,
		componantName: function() {
			return this.getComponent( { ...this.$attrs.property , forceType: this.type } ) ;
		} ,
		labelValue: function() {
			return typeof this.label === 'string' ? this.label : this.$attrs.property.localName ;
		}
	} ,
	methods: {
		getComponent: function( property ) {
			if ( property.forceType ) return property.forceType ;

			if ( property.in ) return 'in-list' ;

			switch( property.type ) {
				case 'link':
				case 'multiLink':
					return 'in-link' ;
				case 'boolean':
					return 'boolean' ;
				case 'float':
				case 'integer':
				case 'number':
					return 'compare-number' ;
				case 'date':
					return 'compare-date' ;
				default:
					console.log( 'Warning: component filter not found on property' , property.name , property ) ;
			}
			return null ;
		}
	}
} ;
</script>
