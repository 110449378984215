<template>
	<div class="document">
		<div class="cols2">
			<section data-name:identification>
				<header>{{ i18n('products.identification') }}</header>
				<div class="grid-align">
					<input-combo v-model="value['type']" v-bind="auto('type')" />
					<input-combo v-model="value['subtype']" v-bind="auto('subtype')" />
					<input-combo v-model="value['shortTypeCode']" v-bind="auto('shortTypeCode')" />
					<input-combo v-model="value['barcode']" v-bind="auto('barcode')" />
					<input-combo v-model="value['purchasePrice']" v-bind="auto('purchasePrice')" />
					<input-combo v-model="value['sellingPrice']" v-bind="auto('sellingPrice')" />
					<input-combo v-model="value['archived']" v-bind="auto('archived')" />
				</div>
			</section>
			<section v-if="!edit" v-grid-area:metas>
				<header>{{ i18n('products.metas') }}</header>
				<div class="grid-align">
					<input-combo :model-value="document['creationDate']" :edit="false" v-bind="auto('creationDate')" />
					<input-combo :model-value="document['creationUser']" :edit="false" v-bind="auto('creationUser')" />
					<input-combo :model-value="document['modificationDate']" :edit="false" v-bind="auto('modificationDate')" />
					<input-combo :model-value="document['modificationUser']" :edit="false" v-bind="auto('modificationUser')" />
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import mixin from './mixin.js' ;

export default {
	mixins: [mixin]
} ;
</script>

<style scoped>
.document {
	grid-template:
		"identification metas" / 1fr 1fr;
}
@media (max-width: 1024px) {
	.document {
		grid-template:
			"identification"
			"metas" / 1fr ;
	}
}
</style>