<template>
	<div v-if="ready" class="value backLink">
		<div class="collection">
			bob
			<RestQueryDocument
				v-for="document in collection"
				:key="document._id"
				v-slot="{ schema , document ,workingDocument}"
				:rest-query="{collection:document._collection , document: document._id}"
			>
				{{ workingDocument }}
				<div v-for="property in schema.editables" :key="property.name">
					{{ property.name }}
					{{ workingDocument[property.name] }}
					<!-- {{ document[property.name] }} -->
				</div>
				<input-combo
					v-for="property in schema.editables"
					:key="property.name"
					v-model="workingDocument[property.name]"
					:original-value="document[property.name]"
					:label="true"
					:property="property"
					:edit="true"
				/>
			</RestQueryDocument>
		</div>
	</div>
</template>

<script>
import SetupCollection from '@/libRestQuery/vue/setups/Collection.js' ;
import mixin from './mixin' ;
import { inject } from 'vue' ;

export default {
	mixins: [mixin] ,
	props: {
		modelValue: {
			type: Object ,
			default: () => {}
		} ,
		properties: {
			type: Array ,
			default: () => ['hid']
		} ,
		showHeader: {
			type: Boolean ,
			default: false
		}
	} ,
	setup( props , context ) {
		// FIXME: when in collection, there is no provided documents
		var originalDocument = inject( 'document' ) ;
		var id = originalDocument.value?._id ;

		if ( props.property.anyCollection ) {
			id += `@${originalDocument.value._collection}` ;
		}

		return SetupCollection( props , context , {
			collection: props.property.collection ,
			query: {
				filters: {
					[props.property.path]: {
						'$in': [id]
					}
				}
			} ,
			queryStats: false
		} ) ;
	} ,
	computed: {
		getColumnsLength: function() {
			return this.properties.length ;
		} ,
		propertyList: function() {
			return this.properties.map( property => this.schema.properties[property] ) ;
		}
	}
} ;
</script>
<style scoped>
.embedded {
	--columns: v-bind('getColumnsLength');
}
</style>