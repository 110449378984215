<template>
	<div class="multilink">
		<input-combo
			v-for="(document,index) in value"
			:key="document"
			v-model="value[index]"
			:original-value="originalValue?.[index]"
			:property="property"
			:placeholder="placeholder"
			type="link"
			:edit="true"
		/>
		<div class="action add" @click.prevent="add">
			<span class="material-symbols">add</span>Ajouter
		</div>
	</div>
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin] ,
	props: {
		modelValue: {
			type: [Array] ,
			default: ()=>[]
		}
	} ,
	methods: {
		add: function() {
			this.value.push( '' ) ;
		}
	}
} ;
</script>