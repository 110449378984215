<template>
	<textarea
		v-model="value"
		:name="property.name"
		class="value"
		:required="required"
		:disabled="disabled"
		:minLength="property.minLength"
		:maxLength="property.maxLength"
		:pattern="pattern"
		:placeholder="placeholder"
		:size="value?.length"
	/>
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin]
} ;
</script>