<template>
	<div v-if="edit" class="actions">
		<div class="actions-start">
			<Button-icon class="action" icon="undo" :text="i18n('go_back')" @click="action('switchEdit')" />
		</div>
		<Button-icon class="action ok" icon="save" :text="i18n('save')" @click="action('saveAndDisableEdit')" />
	</div>
	<div v-else class="actions">
		<div class="actions-start">
			<Button-icon class="action" icon="grid_view" :text="i18n('back_collection')" :to="navigation.back" />
		</div>

		<Button-icon v-if="hasPdfExport()" icon="download" :text="i18n('export_pdf')" @click="action('exportPdf')" />

		<Button-icon class="action" icon="file_copy" :text="i18n('duplicate')" @click="action('duplicate')" />

		<Button-icon v-if="document._frozen" class="action" icon="lock" :text="i18n('unlock')" @click="action('unfreeze')" />
		<template v-else>
			<Button-icon class="action" icon="edit" :text="i18n('edit')" @click="action('switchEdit')" />
			<Button-icon v-if="false" class="cancel" icon="delete" :text="i18n('delete')" @click.prevent="action('remove')" />
			<Button-icon v-if="schema.schema.freezable" class="action" icon="lock_open_right" :text="i18n('lock')" @click="action('freeze')" />
		</template>
	</div>
</template>

<script>
import Navigation from '@/stores/Navigation.js' ;
import pdf from '@/lib/pdf.js' ;

export default {
	inject: ['collectionName'] ,
	props: {
		document: {
			type: Object ,
			default: () => {}
		} ,
		schema: {
			type: Object ,
			default: () => {}
		} ,
		edit: {
			type: Boolean ,
			default: false
		}
	} ,
	emits: ['action:exec'] ,
	data: function() {
		return {
			navigation: Navigation()
		} ;
	} ,
	methods: {
		hasPdfExport: function() {
			return pdf.hasTemplate( this.collectionName ) ;
		} ,
		action: function( name , args ) {
			this.$emit( `action:exec` , name , args ) ;
		}
	}
} ;
</script>

<style scoped src="../css/actions.css" />
