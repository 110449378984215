<template>
	<Query v-bind="$attrs" :filters="filters" />
</template>

<script>
import Query from '@/libRestQuery/vue/components/Query.vue' ;

export default {
	components: {
		Query
	} ,
	inject: ['collectionName'] ,
	inheritAttrs: false ,

	data: function() {
		var filters = [] ;
		switch( this.collectionName ) {
			case 'Carousels':
				filters = [ { name: 'archived' } , { name: 'season' } ] ;
				break ;
			case 'Credits':
				filters = [ { name: 'client' } , { name: 'creationDate' , operator: '$gte' , label: 'Créé du' } , { name: 'creationDate' , operator: '$lte' , label: 'au' } ] ;
				break ;
			case 'Deposits':
				filters = [ { name: 'state' } , { name: 'creationDate' , operator: '$gte' , label: 'Créé du' } , { name: 'creationDate' , operator: '$lte' , label: 'au' } ] ;
				break ;
			case 'Invoices':
				filters = [
					{ name: 'archived' } ,
					{ name: 'state' } ,
					{ name: 'client' } ,
					{ name: 'creationDate' , operator: '$gte' , label: 'Créé du' } ,
					{ name: 'creationDate' , operator: '$lte' , label: 'au' }
				] ;
				break ;
			case 'Organizations':
				// FIXME: DO NOT WORK AS IS
				filters = [
					{ name: 'OPEN-PERIODS' } ,
					{ name: 'groupName' } ,
					{ name: 'tags' } ,
					{ name: 'creationDate' , operator: '$gte' , label: 'Créé du' } ,
					{ name: 'creationDate' , operator: '$lte' , label: 'au' }
				] ;
				break ;
			case 'Payments':
				filters = [
					{ name: 'archived' } ,
					{ name: 'state' } ,
					{ name: 'paymentMethod' } ,
					{ name: 'paymentDate' , operator: '$gte' , label: 'Payé du' } ,
					{ name: 'paymentDate' , operator: '$lte' , label: 'au' }
				] ;
				break ;
			case 'Products':
				filters = [ { name: 'type' } , { name: 'subtype' } ] ;
				break ;
			case 'Returns':
				filters = [ { name: 'state' } ] ;
				break ;
			case 'Users':
				filters = [
					{ name: 'archived' } , { name: 'lastVisit' , operator: '$gte' , label: 'Actif du' } , { name: 'lastVisit' , operator: '$lte' , label: 'au' }
				] ;
				break ;
			default:
				filters = null ;
				break ;
		}
		return {
			filters: filters
		} ;
	}
} ;
</script>