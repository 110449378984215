<template>
	<div class="Webpage">
		<article>
			<h1>Session : {{ accountStore.session?.userLogin }}</h1>
			<div class="session">
				<Button-icon class="option" :icon="config.darkMode?'dark_mode':'light_mode'" :text="i18n('option.brightness')" @click="config.switchDarkMode()" />
				<Button-icon class="option" :icon="config.layout==='Desktop'?'computer':'smartphone'" :text="i18n('option.display')" @click="config.switchMobile()" />
				<Button-icon class="option" :icon="'help'" :text="i18n('option.info')" to="/infos" />
				<Button-icon class="option" :icon="'power_settings_new'" :text="i18n('option.logout')" @click="disconnect" />
			</div>
		</article>
	</div>
</template>

<script>
import Config from '@/stores/Config.js' ;
import AccountStore from '@/libRestQuery/vue/store/Account/index.js' ;
export default {
	setup() {
		return { accountStore: AccountStore() } ;
	} ,
	data: function() {
		return {
			config: Config()
		} ;
	} ,
	methods: {
		disconnect: function() {
			this.accountStore.disconnect()
				.then( () => {
					this.$router.push( {
						name: 'Login'
					} ) ;
				} ) ;
		}
	}
} ;
</script>

<style scoped>

</style>