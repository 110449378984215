import Collections from '@/libRestQuery/vue/store/Collections/index.js' ;
import Collection from '@/libRestQuery/js/Collection.js' ;

import { provide , watch , computed , reactive } from 'vue' ;

import { Timer } from '@/libRestQuery/js/Timers.js' ;

export default function( props , context , restQuery ) {
	var timer = new Timer( 'Setup Collection' ) ;
	timer.start() ;

	const options = Object.assign( {
		autoload: true ,
		queryStats: true
	} , props.restQuery , restQuery ) ;

	const collectionName = options.collection ;

	const store = Collections( collectionName ) ;

	const schema = computed( () => store.schema ) ;
	const collection = computed( () => store.collection( query ) ) ;
	const ready = computed( () => !! ( schema.value && collection.value ) ) ;
	const collectionMeta = computed( () => store.collectionMeta( query ) ) ;

	const selected = reactive( new Set() ) ;

	provide( 'collectionName' , collectionName ) ;
	provide( 'schema' , schema ) ;
	provide( 'collection' , collection ) ; // Not sure it can be useful

	const defaultQuery = {
		method: null ,
		skip: null ,
		limit: null ,
		sortName: null ,
		sortOrder: null ,
		search: null ,
		filters: {} ,
		params: {} // simple key-val, used for custom methods
	} ;

	const query = reactive( Object.assign( {} , defaultQuery , options.query ) ) ;

	watch( () => query , () => refresh() , { deep: true } ) ;
	watch( () => query.search , () => query.skip = null ) ;
	// watch( () => query.filters , () => query.skip = null , { deep: true } ) ;


	// FIXME: Replace, not merge (but if replace filters, it would crash filter component)
	// React to props and url changes
	// Props should replace local state totally, NOT MERGE
	// BEWARE SETTING SEARCH & filters TRIGGER SKIP=null!!!
	if ( props.restQuery?.query ) {
		watch( () => props.restQuery.query , () => {
			let localQuery = Collection.queryToString( query ) ;
			let propsQuery = Collection.queryToString( props.restQuery.query ) ;
			if ( localQuery === propsQuery ) return ;

			Object.assign( query , defaultQuery , props.restQuery.query ) ;
		} , { deep: true } ) ;
	}

	const refresh = function() {
		return store.getCollection( query )
			.then( () => options.queryStats && ! collectionMeta.value && store.getCollectionMeta( query ) ) ;
	} ;

	if ( ! collection.value && options.autoload ) refresh() ;


	timer.end() ;

	return {
		collectionName ,
		store ,

		ready ,
		schema ,
		collection ,
		collectionMeta ,
		query ,
		selected ,

		refresh
	} ;
}
