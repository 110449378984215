<template>
	<Teleport to="body">
		<div v-if="visible" class="debug">
			<button @click="visible=false">Close</button>

			<div v-for="timer of getTimers()" :key="timer.name">
				{{ timer.name }} -
				{{ timer.samples }} samples -
				Avg: {{ timer.average }}ms -
				Total: {{ timer.total }}ms -
				Slowest: {{ timer.slowest }}ms -
				Quickest: {{ timer.quickest }}ms
			</div>

			<div class="content">
				<div class="row head">
					<div>name</div>
					<div>localName</div>
					<div>type</div>
					<div>indexed</div>
					<div>optional</div>
					<div v-if="document">value</div>
				</div>
				<div v-for="property in schema" :key="property.name" class="row body">
					<div>{{ property.name }}</div>
					<div>{{ property.localName }}</div>
					<div>{{ property.type }}</div>
					<div>{{ indexed[property.name]?true:false }}</div>
					<div>{{ property.required ? 'required' : '' }}</div>
					<pre v-if="document">{{ document[property.name] }}</pre>
				</div>
			</div>
		</div>
	</Teleport>
</template>

<script>
import Collections from '@/libRestQuery/vue/store/Collections/index.js' ;
import { Timers } from '@/libRestQuery/js/Timers.js' ;

export default {
	data: function() {
		return {
			visible: false ,
			schema: {} ,
			document: {}
		} ;
	} ,
	mounted: function() {
		document.addEventListener( 'debug' , () => this.show() ) ;
		document.addEventListener( 'keydown' , event => {
			if ( event.ctrlKey && event.altKey && event.key === 'd' ) {
				this.show() ;
			}
		} ) ;
	} ,
	methods: {
		getTimers: function() {
			return Timers.getAll() ;
		} ,
		show: function() {
			let collectionName = this.$route.params.app ,
				documentName = this.$route.params.document ;

			if ( this.visible || ! collectionName ) return this.visible = false ;

			const store = Collections( collectionName ) ;

			this.schema = store.schema.contents ;
			this.indexed = store.schema.indexes ;
			this.document = documentName ? store.document( documentName ) : {} ;
			this.visible = true ;

			return true ;
		}
	}
} ;
</script>

<style src="../css/debug.css" />