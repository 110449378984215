<template>
	<input
		v-model="value"
		:name="property.name"
		:type="type"
		class="value"
		:required="required"
		:disabled="disabled"
		:minLength="property.minLength"
		:maxLength="property.maxLength"
		:pattern="pattern"
		:placeholder="placeholder"
		:size="inputSize"
	/>
</template>

<script>
/* Accepted values for ui.type:
text, search, url, tel, email, password
*/
import mixin from './mixin' ;

export default {
	mixins: [mixin] ,
	props: {
		pattern: {
			type: String ,
			default: null
		}
	} ,
	computed: {
		type: function() {
			if ( this.property.ui?.type ) return this.property.ui.type ;
			return this.property.type === 'email' ? 'email' : 'text' ;
		}
	}
} ;
</script>
