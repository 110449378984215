<template>
	<span class="value number">{{ localValue }}</span>
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin] ,
	props: {
		modelValue: {
			type: [String , Number] ,
			default: ''
		}
	} ,
	computed: {
		localValue: function() {
			if ( ! this.property.ui?.format || ! this.value ) return this.value ;
			var intl = new Intl.NumberFormat( 'fr-FR' , this.property.ui?.format ) ;

			if ( this.property.ui?.format.style === 'percent' && this.value > 1 ) {
				return intl.format( this.value / 100 ) ;
			}
			return intl.format( this.value ) ;
		}
	}
} ;
</script>
