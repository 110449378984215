export default function I18n( i18n , fallbackName ) {
	this.dictionnaries = {} ;
	this.fallbackName = fallbackName || 'misc' ;

	if ( ! this.dictionnaries[this.fallbackName] ) {
		this.dictionnaries[this.fallbackName] = {} ;
	}
	this.addDictionnaries( i18n ) ;
}

I18n.prototype.addDictionnaries = function( dictionnaries ) {
	for( let [space , value] of Object.entries( dictionnaries ) ) {
		this.addDictionnary( value , space ) ;
	}
} ;

I18n.prototype.addDictionnary = function( dictionnary , space = this.fallbackName ) {
	let name = space.toLowerCase() ;
	this.dictionnaries[name] ??= {} ;
	Object.assign( this.dictionnaries[name] , dictionnary ) ;
} ;

I18n.prototype.get = function( key , space = this.fallbackName , logMissing ) {
	var str = this.dictionnaries[space]?.[key] ;
	if ( ! str ) {
		if ( logMissing ) console.log( 'I18N key not found' , key , space ) ;
		str = key.split( '.' ).pop() ;
	}
	return str ;
} ;