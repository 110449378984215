<template>
	<pre class="value text" v-text="value" />
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin] ,
	props: {
		modelValue: {
			type: [String , Number] ,
			default: null
		}
	}
} ;
</script>

<style scoped>
pre {
	margin: unset;
	display: inline-block;
}
</style>