import fetchImage from '../fetchImage.js' ;

export default async function( data ) {
	var deliveryDate = new Intl.DateTimeFormat( 'fr-FR' , {
		year: 'numeric' ,
		month: '2-digit' ,
		day: '2-digit'
	} ).format( new Date( data.deliveryDate ) ) ;

	var totalQuantity = 0 ;
	var productsRows = data.products.map( product => {
		totalQuantity += product.quantity ;
		return [
			{
				text: `${product.product?.barcode}` ,
				border: [false , true , false , true] ,
				margin: [0 , 1 , 0 , 1]
			} ,
			{
				text: `${product.product?.subtype}` ,
				border: [false , true , false , true] ,
				alignment: 'left' ,
				margin: [0 , 1 , 0 , 1]

			} ,
			{
				text: `${product.product?.sellingPrice || ''}` ,
				border: [false , true , false , true] ,
				alignment: 'right' ,
				margin: [0 , 1 , 0 , 1] ,
				textTransform: 'uppercase'
			} ,
			{
				text: `${product.quantity || ''}` ,
				border: [false , true , false , true] ,
				alignment: 'center' ,
				margin: [0 , 1 , 0 , 1]

			}
		] ;
	} ) ;
	productsRows.push( [ {
		text: `Total` ,
		border: [false , true , false , true] ,
		margin: [0 , 1 , 0 , 1]
	} ,
	{
		text: `` ,
		border: [false , true , false , true] ,
		alignment: 'left' ,
		margin: [0 , 1 , 0 , 1]

	} ,
	{
		text: `` ,
		border: [false , true , false , true] ,
		alignment: 'right' ,
		margin: [0 , 1 , 0 , 1] ,
		textTransform: 'uppercase'
	} ,
	{
		text: `${totalQuantity}` ,
		border: [false , true , false , true] ,
		alignment: 'center' ,
		margin: [0 , 1 , 0 , 1]

	} ] ) ;
	console.log( productsRows ) ;

	return {
		pageMargins: [40 , 150 , 40 , 180] ,
		info: {
			title: `123Bijoux - Dépôt ${data.orderNumber}` , // Client + Num Dépôt
			author: 'Application Murray' , //Nom application
			subject: 'Bon de dépôt'
		} ,
		defaultStyle: {
			columnGap: 20
		} ,

		header: {
			margin: [40 , 40 , 40 , 40] ,
			columns: [
				{
					image: await fetchImage( '/logo-123bijoux.png' ) ,
					width: 150
				} ,
				{
					stack: [
						{
							text: 'Bon de dépôt' ,
							color: '#333333' ,
							width: '*' ,
							fontSize: 28 ,
							bold: true ,
							alignment: 'right'
						} ,
						{
							text: `N°${data.orderNumber}` , //Num facture
							bold: true ,
							color: '#333333' ,
							fontSize: 11 ,
							alignment: 'right'

						} ,
						{
							text: `${deliveryDate}` ,
							bold: true ,
							color: '#333333' ,
							fontSize: 11 ,
							alignment: 'right'
						}
					]
				}

			]
		} ,
		content: [
			{
				columns: [
					{
						stack: [
							{
								text: '15 rue Roland Moreno\n17200 Saint Sulpice de Royan' ,
								margin: [0 , 0 , 0 , 10] ,
								alignment: 'left'
							} ,
							{
								text: '123bijoux@outlook.fr\nTél : 05 46 22 07 65\nFax : 05 67 20 64 36' ,
								alignment: 'left'
							}
						]
					} ,
					{
						stack: [
							{
								text: `${data.client.organizationName} ${data.client.companyName}` , //Nom client
								bold: true ,
								alignment: 'left' ,
								margin: [40 , 0 , 0 , 10]
							} ,
							{
								text: `${data.client.address}\n${data.client.zipCode} ${data.client.city}` , //adresse client
								alignment: 'left' ,
								margin: [40 , 0 , 0 , 0]

							} ,
							{
								text: `\nCODE CLIENT ${data.client.clientCode}` , //Code client
								bold: true ,
								alignment: 'left' ,
								margin: [40 , 0 , 0 , 0]

							}
						]
					}
				]
			} ,
			{
				text: 'Détail dépôt' ,
				fontSize: 14 ,
				alignment: 'left' ,
				margin: [0 , 30 , 0 , 10]
			} ,

			{
				layout: {
					defaultBorder: false ,
					hLineWidth: function() {
						return 1 ;
					} ,
					vLineWidth: function() {
						return 1 ;
					} ,
					hLineColor: function( i ) {
						if ( i === 1 || i === 0 ) {
							return '#bfdde8' ;
						}
						return '#bfdde8' ;
					} ,
					vLineColor: function() {
						return '#bfdde8' ;
					} ,
					hLineStyle: function() {
						return null ;
					} ,
					paddingLeft: function() {
						return 10 ;
					} ,
					paddingRight: function() {
						return 10 ;
					} ,
					paddingTop: function() {
						return 2 ;
					} ,
					paddingBottom: function() {
						return 2 ;
					} ,
					fillColor: function() {
						return '#fff' ;
					}
				} ,

				table: {
					headerRows: 1 ,
					widths: ['auto' , '*' , 'auto' , 'auto'] ,
					body: [
						[
							{
								text: 'Code barre' ,
								fillColor: '#eaf2f5' ,
								border: [false , true , false , true] ,
								margin: [0 , 1 , 0 , 1] ,
								textTransform: 'uppercase'
							} ,
							{
								text: 'Description' ,
								fillColor: '#eaf2f5' ,
								border: [false , true , false , true] ,
								alignment: 'left' ,
								margin: [0 , 1 , 0 , 1] ,
								textTransform: 'uppercase'
							} ,
							{
								text: 'Unitaire' ,
								fillColor: '#eaf2f5' ,
								border: [false , true , false , true] ,
								alignment: 'right' ,
								margin: [0 , 1 , 0 , 1] ,
								textTransform: 'uppercase'
							} ,
							{
								text: 'Quantité' ,
								fillColor: '#eaf2f5' ,
								border: [false , true , false , true] ,
								alignment: 'center' ,
								margin: [0 , 1 , 0 , 1] ,
								textTransform: 'uppercase'
							}
						]
					].concat( productsRows )
				}
			}
		] ,
		footer: function( currentPage , pageCount /*, pageSize*/ ) {
			// you can apply any logic and return any valid pdfmake element
			return [
				{
					margin: [40 , 0 , 40 , 0] ,
					stack: [
						{
							text: '123 Bijoux - SAS au capital de 2000€' ,
							fontSize: 12 ,
							border: [false , true , false , true] ,
							alignment: 'center' ,
							margin: [0 , 25 , 0 , 1]
						} ,
						{
							text: 'N°TVA : FR82 452 219 520 - Siret : 452 219 520 000 23 RCS Saintes - APE : 4684Z' ,
							fontSize: 10 ,
							border: [false , true , false , true] ,
							alignment: 'center' ,
							margin: [0 , 1 , 0 , 5]
						}

					]
				} ,
				{
					text: 'Page ' + currentPage.toString() + ' / ' + pageCount ,
					alignment: 'right' ,
					fontSize: 10 ,
					margin: [40 , 0 , 40 , 0]
				}

			] ;
		}
	} ;
};
