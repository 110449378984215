var Timers = {
	timers: {} ,
	clear: function() {
		this.timers = {} ;
	} ,

	get: function( name ) {
		var timers = Object.values( this.timers[name] ) ;

		// FIXME: bob ?
		var bob = {
			name: name ,
			samples: timers.length ,
			slowest: - Infinity ,
			quickest: Infinity ,
			average: 0 ,
			total: 0
		} ;

		timers.forEach( element => {
			let time = element.end - element.start ;
			bob.slowest = Math.max( time , bob.slowest ) ;
			bob.quickest = Math.min( time , bob.quickest ) ;
			bob.total += time ;
		} ) ;

		bob.total = bob.total.toFixed( 2 ) ;
		bob.average = ( bob.total / bob.samples ).toFixed( 2 ) ;
		bob.slowest = bob.slowest.toFixed( 2 ) ;
		bob.quickest = bob.quickest.toFixed( 2 ) ;

		return bob ;
	} ,
	getAll: function() {
		return Object.keys( this.timers ).map( name => this.get( name ) ) ;
	}
} ;

function Timer( name ) {
	this.name = name ;
	this.run = Math.random() ;
	this.time = {} ;
}

Timer.prototype.start = function() {
	this.time.start = performance.now() ;
} ;

Timer.prototype.end = function() {
	this.time.end = performance.now() ;

	Timers.timers[this.name] ??= {} ;
	Timers.timers[this.name][this.run] ??= {} ;
	Timers.timers[this.name][this.run] = this.time ;
} ;


export { Timer , Timers } ;

/*
export default {
	timers: {} ,

	start: function( name , run ) {
		this.timers[name] ??= {} ;
		this.timers[name][run] ??= {} ;

		this.timers[name][run].start = performance.now() ;
	} ,

	end: function( name , run ) {
		this.timers[name][run].end = performance.now() ;
	} ,

	clear: function() {
		this.timers = {} ;
	} ,

	get: function( name ) {
		var timers = Object.values( this.timers[name] ) ,
			total = 0 ;

		timers.forEach( element => total += element.end - element.start ) ;
		// add slowest
		// add quickest
		return {
			name: name ,
			samples: timers.length ,
			average: ( total / timers.length ).toFixed( 2 ) ,
			total: total.toFixed( 2 )
		} ;
	} ,
	getAll: function() {
		return Object.keys( this.timers ).map( name => this.get( name ) ) ;
	}
} ;
*/