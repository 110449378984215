import { watch , inject , computed , ref } from 'vue' ;

export default function( props , context ) {
	const schema = inject( 'schema' ) ;

	const debounceSearchTimeout = ref( null ) ;

	const filterList = computed( () => {
		if ( props.filters ) {
			return props.filters.map( filter => {
				if ( ! schema.value.filtrables[filter.name] ) alert( filter.name ) ;
				return {
					...schema.value.filtrables[filter.name] ,
					operator: filter.operator || null ,
					label: filter.label
				} ;
			} ) ;
		}
		return Object.values( schema.value.filtrables ) ;
	} ) ;

	const query = computed( {
		get() {
			return props.modelValue ;
		} ,
		set( query ) {
			// FIXME: Is generally not called, the watcher is the one that
			// trigger the emits
			console.log( 'AAAAAAA BUGGGGGGG!!!' ) ;

			context.emit( 'update:modelValue' , query ) ;
		}
	} ) ;
	const debounceSearch = computed( {
		get() {
			return query.value.search ;
		} ,
		set( search ) {
			clearTimeout( debounceSearchTimeout.value ) ;
			debounceSearchTimeout.value = setTimeout( () => {
				query.value.search = search ;
			} , 500 ) ;
		}
	} ) ;

	watch( () => query , () => {
		context.emit( 'update:modelValue' , query.value ) ;
	} , { deep: true } ) ;

	return {
		schema ,
		debounceSearch ,
		query ,
		filterList
	} ;
}
