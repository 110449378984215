<template>
	<div class="document">
		<div class="cols2">
			<section v-grid-area:identification>
				<header>{{ i18n('credits.identification') }}</header>
				<div class="grid-align">
					<input-combo v-model="value['client']" v-bind="auto('client')" />
					<input-combo v-model="value['creditNumber']" v-bind="auto('creditNumber')" />
					<input-combo v-model="value['invoiceNumber']" v-bind="auto('invoiceNumber')" />
					<input-combo v-model="value['creditDate']" v-bind="auto('creditDate')" />
				</div>
			</section>
			<section v-if="!edit" v-grid-area:metas>
				<header>{{ i18n('invoices.metas') }}</header>
				<div class="grid-align">
					<input-combo :model-value="document['creationDate']" :edit="false" v-bind="auto('creationDate')" />
					<input-combo :model-value="document['creationUser']" :edit="false" v-bind="auto('creationUser')" />
					<input-combo :model-value="document['modificationDate']" :edit="false" v-bind="auto('modificationDate')" />
					<input-combo :model-value="document['modificationUser']" :edit="false" v-bind="auto('modificationUser')" />
				</div>
			</section>
			<section v-grid-area:organization>
				<header>{{ i18n('invoices.organization') }}</header>
				<div class="grid-align">
					<input-combo v-model="value['organizationName']" v-bind="auto('organizationName')" />
					<input-combo v-model="value['companyName']" v-bind="auto('companyName')" />
					<input-combo v-model="value['address']" v-bind="auto('address')" />
					<input-combo v-model="value['zipCode']" v-bind="auto('zipCode')" />
					<input-combo v-model="value['city']" v-bind="auto('city')" />
					<input-combo v-model="value['country']" v-bind="auto('country')" />
				</div>
			</section>
			<section v-grid-area:amount>
				<header>{{ i18n('invoices.amount') }}</header>
				<div class="grid-align">
					<input-combo v-model="value['excludingTaxAmount']" v-bind="auto('excludingTaxAmount')" />
					<input-combo v-model="value['vatAmount']" v-bind="auto('vatAmount')" />
					<input-combo v-model="value['includingTaxAmount']" v-bind="auto('includingTaxAmount')" />
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import mixin from './mixin.js' ;

export default {
	mixins: [mixin]
} ;
</script>


<style scoped>
.document {
	grid-template:
		"identification metas"
		"organization amount" / 1fr 1fr;
}
@media (max-width: 1024px) {
	.document {
		grid-template:
			"identification"
			"metas"
			"organization"
			"amount" / 1fr;
	}
}
</style>
