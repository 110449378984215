<template>
	<div class="document ">
		<div class="cols2">
			<section v-grid-area:identification>
				<header>{{ i18n('organization.identification') }}</header>
				<div class="grid-align">
					<input-combo v-model="value['clientCode']" v-bind="auto('clientCode')" />
					<input-combo v-model="value['companyName']" v-bind="auto('companyName')" />
					<input-combo v-model="value['contactType']" v-bind="auto('contactType')" />
					<input-combo v-model="value['clientType']" v-bind="auto('clientType')" />
					<input-combo v-model="value['groupName']" v-bind="auto('groupName')" />
					<input-combo v-model="value['familyName']" v-bind="auto('familyName')" />
					<input-combo v-model="value['organizationName']" v-bind="auto('organizationName')" />
				</div>
			</section>

			<section v-if="!edit" v-grid-area:metas>
				<header>{{ i18n('organization.metas') }}</header>
				<div class="grid-align">
					<input-combo :edit="false" v-bind="auto('creationDate')" />
					<input-combo :edit="false" v-bind="auto('creationUser')" />
					<input-combo :edit="false" v-bind="auto('modificationDate')" />
					<input-combo :edit="false" v-bind="auto('modificationUser')" />
				</div>
			</section>

			<section v-grid-area:stickers>
				<header>{{ i18n('organization.stickers') }}</header>
				<div>
					<input-combo v-model="value['tags']" link-to="filter" class="as-tags" v-bind="auto('tags')" :label="false" />
				<!-- <input-combo v-model="value['interest']" v-bind="auto('interest')" /> -->
				</div>
			</section>

			<section v-if="!edit" v-grid-area:deposits>
				<header>
					{{ i18n('organization.deposits') }}
					<router-link class="button" :to="createFrom('deposits')">{{ i18n('btn-add') }}</router-link>
				</header>
				<div>
					<input-combo
						v-bind="auto('deposits',false)"
						:properties="[
							'hid',
							'state'
						]"
					/>
				</div>
			</section>

			<section v-if="!edit" v-grid-area:invoices>
				<header>
					{{ i18n('organization.invoices') }}
					<router-link class="button" :to="createFrom('invoices')">{{ i18n('btn-add') }}</router-link>
				</header>
				<div>
					<input-combo v-bind="auto('invoices',false)" />
				</div>
			</section>

			<section v-if="!edit" v-grid-area:notes>
				<header>{{ i18n('organization.notes') }}</header>
				<div>
					<input-combo
						v-bind="auto('notes',false)"
						:properties="[
							'note'
						]"
						:edit="true"
					/>
				</div>
			</section>

			<section v-grid-area:info>
				<div v-if="hasOpenPeriods">
					<h1>hasOpenPeriods</h1>
					{{ getOpenPeriods?.[0].start }}
				</div>
				<header>{{ i18n('organization.info') }}</header>

				<div class="grid-align">
					<input-combo v-model="value['commercialRate']" v-bind="auto('commercialRate')" />
					<input-combo v-model="value['cashDesks']" v-bind="auto('cashDesks')" />
					<input-combo v-model="value['commercial']" v-bind="auto('commercial')" />
					<input-combo v-model="value['isCentralized']" v-bind="auto('isCentralized')" />
				</div>
			</section>
		</div>

		<section v-grid-area:addresses>
			<header>{{ i18n('addresses') }}</header>
			<input-combo
				v-model="value['addresses']"
				v-bind="auto('addresses')"
				:label="false"
				:properties="[
					'type',
					'address',
					'zipCode',
					'city',
					'country'
				]"
			/>
		</section>

		<section v-grid-area:communications>
			<header>{{ i18n('communications') }}</header>
			<input-combo
				v-model="value['communications']"
				v-bind="auto('communications')"
				:label="false"
				:properties="[
					'firstName',
					'lastName',
					'phone',
					'cellphone',
					'fax',
					'email'
				]"
			/>
		</section>
	</div>
</template>

<script>
import mixin from './mixin.js' ;
import Notes from './Notes-embedded.vue' ;

export default {
	components: { Notes } ,
	mixins: [mixin] ,
	computed: {
		hasOpenPeriods: function() {
			return !! this.getOpenPeriods?.length ;
		} ,
		getOpenPeriods: function() {
			return this.document?.invoicingPeriods.filter( a => ! a.closed ) ;
		}
	}
} ;
</script>

<style scoped>
.document {
	grid-template:
		"identification metas"
		"stickers deposits"
		"invoices notes"
		"info ."
		"addresses addresses"
		"communications communications" / 1fr 1fr;
}
@media (max-width: 1024px) {
	.document {
		grid-template:
			"identification"
			"metas"
			"stickers"
			"deposits"
			"invoices"
			"notes"
			"info"
			"addresses"
			"communications" / 1fr;
	}
}
</style>