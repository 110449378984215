<template>
	<router-link class="account" :to="{path:'/Account'}">
		{{ accountStore.session?.userLogin.slice(0,2).toUpperCase() }}
	</router-link>
</template>

<script>
import Config from '@/stores/Config.js' ;
import AccountStore from '@/libRestQuery/vue/store/Account/index.js' ;

export default {
	setup() {
		return { accountStore: AccountStore() } ;
	} ,
	data: function() {
		return {
			config: Config()
		} ;
	} ,
	methods: {
		disconnect: function() {
			this.accountStore.disconnect()
				.then( () => {
					this.$router.push( {
						name: 'Login'
					} ) ;
				} ) ;
		}
	}
} ;
</script>

<style scoped>
.account {
	background-color:var(--color-text);
	color:var(--color-background);
	font-weight:800;
	font-size:1em!important;
	line-height:2em;

	padding:0.2em!important;
	margin:0.5em;
	width: 2em;
	display: inline-block;
	height: 2em;
	border-radius:1em;
	text-align: center;



}

</style>