<template>
	<div class="document">
		<div class="cols2">
			<section v-grid-area:identification>
				<header>{{ i18n('deposits.identification') }}</header>

				<div class="grid-align">
					<input-combo v-model="value['client']" v-bind="auto('client')" />
					<input-combo v-model="value['orderNumber']" v-bind="auto('orderNumber')" />
					<input-combo v-model="value['state']" v-bind="auto('state')" />
					<input-combo v-model="value['orderDate']" v-bind="auto('orderDate')" />
					<input-combo v-model="value['departureDate']" v-bind="auto('departureDate')" />
					<input-combo v-model="value['deliveryDate']" v-bind="auto('deliveryDate')" />
				</div>
			</section>

			<section v-if="!edit" v-grid-area:metas>
				<header>{{ i18n('deposits.metas') }}</header>
				<div class="grid-align">
					<input-combo v-bind="auto('creationDate')" />
					<input-combo v-bind="auto('creationUser')" />
					<input-combo v-bind="auto('modificationDate')" />
					<input-combo v-bind="auto('modificationUser')" />
				</div>
			</section>
		</div>

		<section v-grid-area:products>
			<header>{{ i18n('deposits.content') }}</header>
			<div>
				<input-combo v-model="value['carousel']" v-bind="auto('carousel')" :default-query="carouselQuery" />
				<input-combo v-model="value['products']" v-bind="auto('products')" />
			</div>
		</section>

		<section v-grid-area:comments>
			<header>{{ i18n('deposits.comment') }}</header>
			<div>
				<input-combo v-model="value['deliveryComment']" v-bind="auto('deliveryComment')" />
			</div>
		</section>
	</div>
</template>

<script>
import mixin from './mixin.js' ;
import Collections from '@/libRestQuery/vue/store/Collections/index.js' ;

export default {
	mixins: [mixin] ,
	data: function() {
		return {
			carouselQuery: {
				filters: {
					archived: {
						$eq: false
					}
				}
			}
		} ;
	} ,
	watch: {
		'value.carousel': function() {
			if ( ! this.value.carousel || typeof this.value.carousel !== 'string' ) return ;

			Collections( 'Carousels' ).restQueryCollection.document( this.value.carousel ).get()
				.then( document => {
					this.value['products'] = document.products
						.filter( product => product.product?._id )
						.map( product => {
							return {
								product: { _id: product.product._id } ,
								quantity: product.quantity
							} ;
						} ) ;
				} ) ;
		}
	}
} ;
</script>


<style scoped>
.document {
	grid-template:
		"identification metas"
		"products products"
		"comments comments" / 1fr 1fr;
}
@media (max-width: 1024px) {
	.document {
		grid-template:
			"identification"
			"metas"
			"products"
			"comments" / 1fr;
	}
}
</style>