import { defineStore , getActivePinia } from 'pinia' ;
import actions from './actions.js' ;
import restQuery from '@/restQuery/index.js' ;

export default defineStore( `RestQueryAccount` , {
	state: function() {
		return {
			session: useRestqueryAccount()
		} ;
	} ,
	getters: {
		restQueryAccount: () => restQuery.account
	} ,
	actions
} ) ;


import { ref } from 'vue' ;
function useRestqueryAccount() {
	const session = ref( null ) ;
	session.value = restQuery.account.session ;

	restQuery.account.onChange( sessionValue => {
		session.value = sessionValue ;

		if ( ! session.value ) {
			getActivePinia()._s.forEach( store => {
				if ( store.$id.startsWith( 'RestQuery:' ) ) {
					console.log( 'Clear store' , store.$id ) ;
					store.$reset() ;
				}
				else {
					console.log( 'Keep store' , store.$id ) ;
				}
			} ) ;
		}
	} ) ;

	return session ;
}