export default {
	props: {
		restQuery: {
			type: Object ,
			required: true
		}
	} ,
	methods: {
		auto: function( propertyName , label = true ) {
			if ( ! this.schema.properties[propertyName] ) alert( `Unknown Property: ${propertyName}` ) ;

			return {
				property: this.schema.properties[propertyName] ,
				modelValue: this.document[propertyName] ,
				originalValue: this.document?.[propertyName] ,
				label: label
			} ;
		}
	}
} ;