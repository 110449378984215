<template>
	<div class="document">
		<div class="cols2">
			<section v-grid-area:identification>
				<header>{{ i18n('invoices.identification') }}</header>
				<div class="grid-align">
					<input-combo v-model="value['client']" v-bind="auto('client')" />
					<input-combo v-model="value['invoiceNumber']" v-bind="auto('invoiceNumber')" />
					<input-combo v-model="value['invoiceDate']" v-bind="auto('invoiceDate')" />
					<input-combo v-model="value['state']" v-bind="auto('state')" />
					<input-combo v-model="value['archived']" v-bind="auto('archived')" />
				</div>
			</section>
			<section v-if="!edit" v-grid-area:metas>
				<header>{{ i18n('invoices.metas') }}</header>
				<div class="grid-align">
					<input-combo v-bind="auto('creationDate')" />
					<input-combo v-bind="auto('creationUser')" />
					<input-combo v-bind="auto('modificationDate')" />
					<input-combo v-bind="auto('modificationUser')" />
				</div>
			</section>
			<section v-grid-area:total>
				<header>{{ i18n('invoices.total') }}</header>
				<div class="grid-align">
					<input-combo v-model="value['excludingTaxAmount']" v-bind="auto('excludingTaxAmount')" />
					<input-combo v-model="value['vatRate']" v-bind="auto('vatRate')" />
					<input-combo v-model="value['vatAmount']" v-bind="auto('vatAmount')" />
					<input-combo v-model="value['includingTaxAmount']" v-bind="auto('includingTaxAmount')" />
					<input-combo v-model="value['amountPaid']" v-bind="auto('amountPaid')" />
				</div>
			</section>
			<section v-grid-area:address>
				<header>{{ i18n('invoices.adresse') }}</header>
				<div class="grid-align">
					<input-combo v-model="value['organizationName']" v-bind="auto('organizationName')" />
					<input-combo v-model="value['companyName']" v-bind="auto('companyName')" />
					<input-combo v-model="value['address']" v-bind="auto('address')" />
					<input-combo v-model="value['zipCode']" v-bind="auto('zipCode')" />
					<input-combo v-model="value['city']" v-bind="auto('city')" />
					<input-combo v-model="value['country']" v-bind="auto('country')" />
				</div>
			</section>
		</div>
		<section v-grid-area:products>
			<header>{{ i18n('invoices.title') }}</header>
			<div>
				<input-combo v-model="value['products']" v-bind="auto('products')" />
			</div>
		</section>
	</div>
</template>

<script>
import mixin from './mixin.js' ;
import Collections from '@/libRestQuery/vue/store/Collections/index.js' ;

export default {
	mixins: [mixin] ,
	watch: {
		'value.client': function() {
			if ( ! this.edit ) return ;

			if ( ! this.value.client || typeof this.value.client !== 'string' ) return ;

			Collections( 'Organizations' ).restQueryCollection.document( this.value.client ).get()
				.then( document => {
					this.value.organizationName = document.organizationName ;
					this.value.companyName = document.companyName ;
					this.value.address = document.address ;
					this.value.zipCode = document.zipCode ;
					this.value.city = document.city ;
					this.value.country = document.country ;
				} ) ;
		} ,
		'value.vatRate': 'updateCustom' ,
		'value.products': {
			handler: function() {
				this.updateCustom() ;
			} ,
			deep: true
		}
	} ,
	methods: {
		updateCustom: function() {
			if ( ! this.edit ) return ;

			var total = 0 ;
			for( let product of this.value.products ) {
				if ( product.product ) {
					Collections( 'Products' ).restQueryCollection.document( product.product ).get()
						.then( document => {
							product.description = document.hid ; // document.type + document.shortTypeCode + document.subtype ;
							product.barcode = document.barcode ;
							product.unitPrice = document.purchasePrice ;
							product.unitFinalPrice = document.sellingPrice ;
						} ) ;
				}

				product.price = Math.round( parseFloat( product.unitPrice || 0 ) * parseInt( product.quantity || 0 , 10 ) ) ;
				total += product.price ;
			}

			this.value.excludingTaxAmount = total ;
			this.value.vatAmount = this.value.excludingTaxAmount * ( this.value.vatRate || 0 ) / 100 ;
			this.value.includingTaxAmount = this.value.excludingTaxAmount + this.value.vatAmount ;
		}
	}
} ;
</script>

<style scoped>
.document {
	grid-template:
		"identification metas"
		"total address"
		"products products" / 1fr 1fr;
}
@media (max-width: 1024px) {
	.document {
		grid-template:
			"identification"
			"metas"
			"total"
			"address"
			"products" / 1fr;
	}
}
</style>