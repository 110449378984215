<template>
	<div class="document">
		<div class="cols2">
			<section data-name:identification>
				<header>{{ i18n('payments.identification') }}</header>
				<div class="grid-align">
					<input-combo v-model="value['client']" v-bind="auto('client')" />
					<input-combo v-model="value['invoice']" v-bind="auto('invoice')" />
					<input-combo v-model="value['paymentDate']" v-bind="auto('paymentDate')" />

					<input-combo v-model="value['paymentMethod']" v-bind="auto('paymentMethod')" />
					<input-combo v-model="value['amountPaid']" v-bind="auto('amountPaid')" />


					<input-combo v-model="value['state']" v-bind="auto('state')" />
					<input-combo v-model="value['archived']" v-bind="auto('archived')" />
				</div>
			</section>
			<section v-if="!edit" data-name:metas>
				<header>{{ i18n('payments.metas') }}</header>
				<div class="grid-align">
					<input-combo :model-value="document['creationDate']" :edit="false" v-bind="auto('creationDate')" />
					<input-combo :model-value="document['creationUser']" :edit="false" v-bind="auto('creationUser')" />
					<input-combo :model-value="document['modificationDate']" :edit="false" v-bind="auto('modificationDate')" />
					<input-combo :model-value="document['modificationUser']" :edit="false" v-bind="auto('modificationUser')" />
				</div>
			</section>
		</div>
		<section v-if="!edit" data-name:comments>
			<header>{{ i18n('payments.comments') }}</header>
			<div class="grid-align">
				<input-combo v-model="value['comment']" v-bind="auto('comment')" />
				<input-combo v-model="value['paymentMethodComment']" v-bind="auto('paymentMethodComment')" />
			</div>
		</section>
	</div>
</template>

<script>
import mixin from './mixin.js' ;

export default {
	mixins: [mixin]
} ;
</script>

<style scoped>
.document {
	grid-template:
		"identification metas"
		"comments comments" / 1fr 1fr;
}
@media (max-width: 1024px) {
	.document {
		grid-template:
			"identification"
			"metas"
			"comments" / 1fr;
	}
}
</style>