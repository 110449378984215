<template>
	<div class="multilink">
		<header v-if="showHeader">
			<div v-for="property in propertyList" :key="property.name" class="property">
				{{ property.localName }}
			</div>
		</header>

		<display-link
			v-for="(document , index) in value"
			:key="document"
			:model-value="document"
			:property="property"
			:original-value="originalValue[index]"
			v-bind="$attrs"
		/>
	</div>
</template>

<script>
import displayLink from './link.vue' ;
import mixin from './mixin' ;

export default {
	components: {
		displayLink
	} ,
	mixins: [mixin] ,
	props: {
		id: {
			type: String ,
			default: ''
		} ,
		modelValue: {
			type: Array ,
			default: () => []
		} ,
		properties: {
			type: Array ,
			default: () => ['hid']
		} ,
		showHeader: {
			type: Boolean ,
			default: false
		}
	}
} ;
</script>
