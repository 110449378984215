<template>
	<div class="value embedded">
		<header>
			<div v-for="property in propertyList" :key="property.name" class="property">
				{{ property.localName }}
			</div>
		</header>

		<div v-for="document in value" :key="document" class="document">
			<input-combo
				v-for="property in propertyList"
				:key="property.name"
				v-model="document[property.name]"
				:property="property"
				:original-value="document[property.name]"
			/>
		</div>
	</div>
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin] ,
	props: {
		modelValue: {
			type: Array ,
			default: () => []
		} ,
		properties: {
			type: Array ,
			default: () => []
		}
	} ,
	computed: {
		propertyList: function() {
			if ( this.properties.length ) {
				return this.properties.map( propertyName => this.property.of.properties[propertyName] ) ;
			}
			else if ( this.property.of?.properties ) {
				return Object.values( this.property.of.properties ) ;
			}
			return [] ;
		} ,
		getColumnsLength: function() {
			return this.propertyList.length ;
		}
	}
} ;
</script>

<style scoped>
.embedded {
	--columns: v-bind('getColumnsLength');
}
</style>
